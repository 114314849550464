$navyblue: #0F1626;
$leather: #AB987A;
$corall: #FF533D;
$egg: #F5F5F5;


// General stuff

li { list-style-type: disc; }

p {
    font-size: 16px;
    font-weight: normal;
    line-height: 29px;
    color: #222;
    margin-bottom: 15px;
}

// Top of page
.menu-area {
    background: $leather;

}

.transparent-menu ul li:hover a i {
    color: #FFF;
}
.mean-container .mean-nav ul li { list-style: none;}
#mobile-menu {
    ul {
        li {
            
            a {
                color: #FFF;
                font-size: 19px;

                &:hover {
                    color: #CCC;
                }

            }
            
            .sub-menu {
                li {
                    a {
                        color: #000;
                    }
                }
            }
        }
    }
}

.header-top {
    a {
        color: #fff;
    }
}

// Room

.features-wrap {
    height: 320px;
}

// About

@media screen and (min-width: 991px) {
    .about-img {
        img {
            border-radius: 50%;
            max-width: 300px;
        }
       
    }
}
.about-info-text {
    h2 {
        text-transform: none;
    }
}


// Footer
.footer-section {
    background: $navyblue;
    color: #fff;
    .footer-widget {
        color: #fff;

    }
    p {
        color: #fff;
    }
    a {
        color: #fff;
    }
    .subscribe-form input {
        width: 100%;
        padding: 14px 28px;
        background: $leather;
        border: none;
        color: #fff;
    }

    .footer-logo-bg {

        background: url(/assets/img/logo/footer_logo.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: right;
    }


}